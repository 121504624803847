export const SIDES = {
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM',
  LEFT: 'LEFT'
}

export const AXES = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL'
}