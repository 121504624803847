import { Block, Brick, Door, Flag, Ground, Random, Pipe } from '../models'

export const firstWorld = {
  background: '#6496f5',
  squares: [
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Random, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Random, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Random, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Random, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, Random, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Random, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, null, null
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, null, null
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Random, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Random, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Random, null, null, null, Random, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Random, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Random, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Random, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Brick, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Brick, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Pipe, Pipe, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, Flag, Flag, Flag, Flag, Flag, Flag, Flag, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Door, Door, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, Ground, Ground, Ground, Ground, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ],
    [
      null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
    ]
  ]
}

const columnWithGround = [
  null, null, null, null, null, null, null, null, null, null, null, null, null, null, Ground, Ground
]

const columnWithPlatform = [
  null, null, null, null, null, null, null, null, null, null, Ground, null, null, null, Ground, Ground
]